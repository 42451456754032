import { Button, Snackbar } from "@material-ui/core"
import { Alert } from "@mui/material"
import axios from "axios"
import React, { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { makePartnersService } from "../services/partnersService"
import { modules } from "../utils/Util"

const server_url = process.env?.REACT_APP_SERVER_URL

type EmailUser = {
  type: "email"
  email: string
}

type SmsUser = {
  type: "sms"
  phoneNumber: string
}

type UserData = (EmailUser | SmsUser) & { token: string }

type User = UserData & {
  isAdmin: boolean
  isAM: boolean
  isSuperAdmin: boolean
  sfId: string
  organizationId: string
  partnerName: string
  userId: string
  permissions?: string[]
  name_first?: string
  name_last?: string
  name_full?: string
}

type Context = {
  isAuthenticated: boolean
  isMasquerading: boolean

  checkAuth: (signal?: AbortSignal) => Promise<boolean>
  login: (data: UserData, signal?: AbortSignal) => Promise<void>
  logout: () => void
  masqueradeAs: (userId: string, signal?: AbortSignal) => Promise<void>
  stopMasquerade: () => void

  sendEmail: (email: string, signal?: AbortSignal) => Promise<boolean>
  sendSms: (phoneNumber: string, signal?: AbortSignal) => Promise<boolean>

  activeUser?: User
  user?: User
}

const AuthContext = React.createContext<Context | null>(null)
export const useAuth = () => React.useContext(AuthContext)!
export const useActiveUser = () => React.useContext(AuthContext)!.activeUser!
export const useUser = () => React.useContext(AuthContext)!.user!

const tokenLocalStorageKey = "token"
const activeTokenLocalStorageKey = "activeToken"

const getUser = async (
  token: string,
  userId: string,
  orgRoles: any[],
  data: UserData,
  signal?: AbortSignal
): Promise<User> => {
  const isAdmin = orgRoles.some(({ name }: any) => name === "Case Admin")
  const isSuperAdmin = orgRoles.some(
    ({ name }: any) => name === "Case Super Admin"
  )
  const isAM = orgRoles.some(({ name }: any) => name === "Account Manager")
  const orgRole = orgRoles.find(
    ({ name, organization_id }: any) =>
      !!organization_id && name !== "Service Technician"
  )

  if (!orgRole || !orgRole.organization_id) throw new Error("No permissions")

  const partnersService = makePartnersService(token)
  const permissions = getPermissions(orgRoles, {
    isAdmin,
    isSuperAdmin,
  })

  const orgData = await partnersService.getOrg(
    { id: orgRole.organization_id },
    signal
  )
  if (!orgData) throw new Error("Organization not found")

  const profiles = (await partnersService.getProfiles({ userId }))?.data

  const profile = profiles.find((p: any) => p.partner_id === orgRole.organization_id)

  const name_first = (profile || profiles[0])?.name_first ?? ''
  const name_last = (profile || profiles[0])?.name_last ?? ''
  const name_full = (profile || profiles[0]) && `${name_first} ${name_last}`

  const partnerName = orgData.name
  const sfId = orgData.sf_account_id
  const organizationId = orgData.id

  return {
    sfId,
    organizationId,
    partnerName,
    userId,
    isAdmin,
    isAM,
    isSuperAdmin,
    permissions,
    name_first,
    name_last,
    name_full,
    ...data,
  }
}

const getPermissions = (
  orgRoles: any[],
  { isAdmin, isSuperAdmin }: { isAdmin: boolean; isSuperAdmin: boolean }
): string[] => {
  const moduleNames = Object.values(modules)

  if (isSuperAdmin) return moduleNames

  if (isAdmin) {
    if (orgRoles.some((r) => r.name?.toLowerCase() === "account manager"))
      return moduleNames

    return moduleNames.filter((m) => m !== "validation")
  }

  return moduleNames.filter(
    (m) =>
      orgRoles.some((r) => {
        if (m === "validation" && r.name?.toLowerCase() !== "account manager")
          return false

        return true
      }) && m !== "admin"
  )
}

const checkAuthentication = async (
  token: string,
  signal?: AbortSignal
): Promise<User | undefined> => {
  try {
    const response = await axios({
      method: "GET",
      url: server_url + "/users/protected",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      signal,
    })

    if (response?.data?.success) {
      const userId = response.data.user.id
      const orgRoles: any[] = response.data.user.org_role
      const email = response.data.user.email
      const phoneNumber = response.data.user.phone_number

      const data: UserData = !!email
        ? { type: "email", email, token }
        : { type: "sms", phoneNumber, token }

      return await getUser(token, userId, orgRoles, data, signal)
    }
  } catch (error) {
    console.error("[AuthProvider] checkAuth error: ", error)
  }
  return undefined
}

const authenticateUser = async (
  data: UserData,
  signal?: AbortSignal
): Promise<User | undefined> => {
  try {
    const response = await axios({
      method: "GET",
      url: `${server_url}/users/auth/magiclink/${data.type}`,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        email: data.type === "email" ? data.email : "no_email",
        token: data.token,
        phone_number:
          data.type === "sms" ? data.phoneNumber : "no_phone_number",
      },
      signal,
    })

    if (response?.data?.success && response.data.tokenObject?.permissions) {
      const userId = response.data.tokenObject.permissions.id
      const orgRoles: any[] = response.data.tokenObject.permissions.org_role
      const email = response.data.tokenObject.permissions.email
      const phoneNumber = response.data.tokenObject.permissions.phone_number
      const token = response.data.tokenObject.token

      const data: UserData = !!email
        ? { type: "email", email, token }
        : { type: "sms", phoneNumber, token }

      return await getUser(token, userId, orgRoles, data, signal)
    }
  } catch (error) {
    console.error("[AuthProvider] login error: ", error)
  }
  return undefined
}

const masquerade = async (
  token: string,
  userId: string,
  signal?: AbortSignal
): Promise<User | undefined> => {
  try {
    const response = await axios({
      method: "GET",
      url: `${server_url}/users/auth/masquerade`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      params: {
        user_id: userId,
      },
      signal,
    })

    if (response?.data?.success && response.data.tokenObject?.permissions) {
      const userId = response.data.tokenObject.permissions.id
      const orgRoles: any[] = response.data.tokenObject.permissions.org_role
      const email = response.data.tokenObject.permissions.email
      const phoneNumber = response.data.tokenObject.permissions.phone_number
      const token = response.data.tokenObject.token

      const data: UserData = !!email
        ? { type: "email", email, token }
        : { type: "sms", phoneNumber, token }

      return await getUser(token, userId, orgRoles, data, signal)
    }
  } catch (error) {
    console.error("[AuthProvider] masquerade error: ", error)
  }
  return undefined
}

const sendEmail = async (
  email: string,
  signal?: AbortSignal
): Promise<boolean> => {
  const response = await axios({
    method: "POST",
    url: server_url + "/users/auth/magiclink/email",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email,
      phone_number: "no_phone_number",
    },
    signal,
  })

  return response?.data?.success ?? false
}

const sendSms = async (
  phoneNumber: string,
  signal?: AbortSignal
): Promise<boolean> => {
  const response = await axios({
    method: "POST",
    url: server_url + "/users/auth/magiclink/sms",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      phone_number: phoneNumber,
      email: "no_email",
    },
    signal,
  })

  return response?.data?.success ?? false
}

export type AuthProviderProps = {}

export const AuthProvider = ({
  children,
}: PropsWithChildren<AuthProviderProps>) => {
  const [activeUser, setActiveUser] = useState<User>()
  const [user, setUser] = useState<User>()
  const isAuthenticated = useMemo(() => !!user, [user])
  const isMasquerading = useMemo(
    () => !!activeUser && user?.userId !== activeUser?.userId,
    [activeUser, user?.userId]
  )

  const checkAuth = useCallback(async (signal?: AbortSignal) => {
    const getUserFromLocalStorage = async (
      key: string,
      signal?: AbortSignal
    ) => {
      const token = localStorage?.getItem(key) as string
      if (!token) return undefined

      const rv = await checkAuthentication(token, signal)
      if (!rv) {
        localStorage?.removeItem(key)
      }
      return rv
    }

    const au = await getUserFromLocalStorage(activeTokenLocalStorageKey, signal)
    const u =
      (await getUserFromLocalStorage(tokenLocalStorageKey, signal)) ?? au

    setActiveUser(au)
    setUser(u)
    return !!u
  }, [])

  const login = useCallback(async (data: UserData, signal?: AbortSignal) => {
    localStorage?.removeItem(activeTokenLocalStorageKey)
    localStorage?.removeItem(tokenLocalStorageKey)

    const u = await authenticateUser(data, signal)
    setUser(u)
    if (!!u) {
      localStorage?.setItem(tokenLocalStorageKey, u.token)
    }
  }, [])

  const logout = useCallback(() => {
    localStorage?.removeItem(activeTokenLocalStorageKey)
    localStorage?.removeItem(tokenLocalStorageKey)

    setActiveUser(undefined)
    setUser(undefined)
  }, [])

  const masqueradeAs = useCallback(
    async (userId: string, signal?: AbortSignal) => {
      if (!user || !user.isAdmin) return

      localStorage?.removeItem(activeTokenLocalStorageKey)

      const au = await masquerade(user.token, userId, signal)
      setActiveUser(au)
      if (!!au) {
        localStorage?.setItem(activeTokenLocalStorageKey, au.token)
      }
    },
    [user]
  )

  const stopMasquerade = useCallback(() => {
    localStorage?.removeItem(activeTokenLocalStorageKey)

    setActiveUser(undefined)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isMasquerading,

        checkAuth,
        login,
        logout,
        masqueradeAs,
        stopMasquerade,

        sendSms,
        sendEmail,

        activeUser: activeUser ?? user,
        user,
      }}
    >
      {children}
      {isMasquerading && (
        <Snackbar
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          open
        >
          <Alert
            severity="warning"
            action={
              <Button
                onClick={() => {
                  stopMasquerade()
                  window.location.reload()
                }}
              >
                End
              </Button>
            }
          >
            Masquerading as <strong>{activeUser?.userId}</strong>
          </Alert>
        </Snackbar>
      )}
    </AuthContext.Provider>
  )
}
