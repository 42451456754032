import { getDistance } from "geolib"
import { DateTime } from "luxon"
const appCaseLink = process.env?.REACT_APP_CASE_APP_LINK!

export const transformPhoneNumber = (phone?: string): string => {
  if (!phone) return ""

  phone = phone.replace(/\D/g, "")
  const num = /^\d+$/
  if (!!phone.match(num)) {
    if (phone.length === 10) {
      phone = "+1" + phone
    } else if (phone.length >= 11) {
      phone = "+" + phone
    }
  }

  return phone
}
export const validatePhoneNumber = (phone: string): Boolean => {
  phone = transformPhoneNumber(phone)
  var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  return !!phone.match(phoneno)
}

export const validateEmail = (email: string): Boolean => {
  var emailno =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email.match(emailno)
}

export const distanceInMiles = (from: any, to: any) => {
  let m = getDistance(
    { latitude: from.lat, longitude: from.lon },
    {
      latitude: to.lat,
      longitude: to.lon,
    }
  )
  return `${Math.trunc(m * 0.000621371)} mi`
}

export const getFistLogin = (authorizations: any[]) => {
  if (!authorizations.length) {
    return "-"
  }
  const auth = authorizations.reduce((r, o) => (new Date(o.created_at) < new Date(r.created_at) ? o : r))
  const date = auth?.created_at ? DateTime.fromISO(auth?.created_at) : null
  return date ? date.toLocaleString(DateTime.DATETIME_SHORT) : "-"
}

export const getLastLogin = (authorizations: any[]) => {
  if (!authorizations.length) {
    return "-"
  }
  const auth = authorizations.reduce((r, o) => (new Date(o.created_at) > new Date(r.created_at) ? o : r))
  const date = auth?.created_at ? DateTime.fromISO(auth?.created_at) : null
  return date ? date.toLocaleString(DateTime.DATETIME_SHORT) : "-"
}

export const formatDate = (stringDate: string) => {
  let date = new Date(stringDate)
  let month = date.getMonth() + 1
  let day = date.getDate()
  let year = date.getFullYear()
  return month + "/" + day + "/" + year
}

export const formatDateUTC = (stringDate: string) => {
  let date = new Date(stringDate)
  let month = date.getUTCMonth() + 1
  let day = date.getUTCDate()
  let year = date.getUTCFullYear()
  return month + "/" + day + "/" + year
}

export const getTime = (stringDate: string) => {
  let date = new Date(stringDate)
  let h = date.getHours()
  let m = date.getMinutes()
  return `${h > 12 ? (h - 12 >= 10 ? h - 12 : "0" + (h - 12)) : h >= 10 ? h : "0" + h}:${m >= 10 ? m : "0" + m} ${h > 11 ? "PM" : "AM"}`
}

export const getMinFormat = (mins: any) => {
  return ~~(mins / 60) + " hrs " + (~~mins % 60) + " min"
}

export const isPastDate = (stringDate: string) => {
  let today = new Date()
  let date = new Date(stringDate)

  return date < today
}

export const monthDiff = (firstDate: string, lastDate: string) => {
  let months = 0
  let d1 = new Date(firstDate)
  let d2 = new Date(lastDate)
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

export const dayDiff = (firstDate: string, lastDate: string) => {
  let d1 = new Date(firstDate)
  let d2 = new Date(lastDate)
  let diff = d2.getTime() - d1.getTime()

  return diff / (1000 * 3600 * 24)
}

export const getUrlParam = (name: any) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(name)
}

export const checkIfIsAppLink = () => {
  return window.location.href.indexOf(appCaseLink) >= 0
}

export const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const modules = {
  bids: "bids",
  sites: "sites",
  workorders: "workorders",
  admin: "admin",
  validation: "validation",
  compliance: "compliance",
  // profile: "profile",
  team: "team",
  // contactUs: "contactUs"
}

export const MIN_DISTANCE_FROM_SITE = 5
export const LOCALE_KEY = "locale"

export const currentYear = DateTime.now().year

export const currentYearWeekObject = DateTime.local(currentYear, 12, 31).diff(DateTime.local(currentYear, 1, 1), ["weeks"]).toObject()

export const weekOptions = [...Array(Math.round(currentYearWeekObject.weeks ?? 0)).keys()].map((w) => {
  const weekNumber = w + 1
  const from = DateTime.fromObject({
    weekYear: currentYear,
    weekNumber,
  })
    .startOf("week")
    .minus({ day: 1 })

  const to = DateTime.fromObject({
    weekYear: currentYear,
    weekNumber,
  })
    .endOf("week")
    .minus({ day: 1 })

  return {
    label: `Week #${w + 1} from ${from.monthShort} ${from.day}, ${from.year} to ${to.monthShort} ${to.day}, ${to.year}`,
    id: w + 1,
  }
})

export const getWeekNumber = (monthIndex: number, weekIndex: number, year?: number) => {
  const firstDayOfMonth = DateTime.fromObject({
    year: year ?? currentYear,
    month: monthIndex + 1,
    day: 1,
  }).plus({ weeks: weekIndex })

  const daysToAdd = (7 - firstDayOfMonth.weekday) % 7
  const firstSundayDate = firstDayOfMonth.plus({ days: daysToAdd })

  let weekNumber = +firstSundayDate.weekNumber

  return weekNumber
}

export const getDateByMonthAndWeekNumber = (monthIndex: number, weekIndex: number, year?: number) => {
  const choosedYear = year ?? currentYear

  const date = DateTime.fromObject({
    year: choosedYear,
    month: monthIndex + 1,
    day: 1,
  })
    .plus({ weeks: weekIndex })
    .startOf("week")
    .minus({ day: 1 })

  return date
}

export const getNumberOfWeeksPerMonth = (monthIndex: number, year?: number) => {
  const choosedYear = year ?? currentYear

  const startDate = DateTime.fromObject({
    year: choosedYear,
    month: monthIndex + 1,
    day: 1,
  })

  const daysToAdd = (7 - startDate.weekday) % 7
  const firstSundayDate = startDate.plus({ days: daysToAdd })

  const endDate = firstSundayDate.endOf("month")

  let weeks = Math.ceil(endDate.diff(firstSundayDate, "weeks").weeks)

  return weeks
}

export const decimalNumber = (n: number) => {
  let rounded = Math.round(n * 100) / 100

  if (rounded % 1 === 0) {
    return rounded.toLocaleString()
  } else {
    return rounded.toLocaleString("en-US", { minimumFractionDigits: 2 })
  }
}
