import React, { useCallback, useEffect, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Button,
  TextField,
} from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RightIcon } from "./../../../assets/right.svg"
import { ReactComponent as ClipboardIcon } from "./../../../assets/clipboard-check-b.svg"
import { ReactComponent as CalendarIcon } from "./../../../assets/calendar-b.svg"
import { ReactComponent as TreeIcon } from "./../../../assets/tree.svg"
import { ReactComponent as SweepingIcon } from "./../../../assets/sweeping.svg"
import { ReactComponent as JanitoralIcon } from "./../../../assets/janitoral-b.svg"
import { ReactComponent as CloudSnowIcon } from "./../../../assets/cloud-snow-b.svg"
import { ReactComponent as CloseIcon } from "./../../../assets/close-w.svg"
import { ReactComponent as CloseBIcon } from "./../../../assets/close-b.svg"
import { ReactComponent as CheckIcon } from "./../../../assets/check-g.svg"
import { ReactComponent as LogInIcon } from "./../../../assets/log-in.svg"
import { ReactComponent as PDFIcon } from "./../../../assets/pdf.svg"
import Typography from "@mui/material/Typography"
import {
  decimalNumber,
  distanceInMiles,
  formatDateUTC,
  getUrlParam,
  isPastDate,
} from "../../../utils/Util"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Carousel from "react-material-ui-carousel"
import { Loader } from "../../common/Loader"
import Divider from "@mui/material/Divider"
import Checkbox from "@mui/material/Checkbox"
import { BidServiceMatrix } from "./BidServiceMatrix"
import { BidServiceDetails } from "./BidServiceDetails"
import { useActiveUser, useUser } from "../../../providers/AuthProvider"
import { useGraphQLService } from "../../../services/graphqlService"
import { useImageService } from "../../../services/imageService"
import { useFetchImages } from "../../../utils/ImageHelper"
import attentiveLogo from "../../../assets/attentive_logo.png"
import useI18n from "../../../hooks/useI18n"

function BidDetailsDesktop(props: any) {
  const { token } = useUser()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const imageService = useImageService()
  const imageHelper = useFetchImages()
  const classes = useStyles()
  const [details, setDetails] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [loadingLocation, setLoadingLocation] = useState(true)
  const [offers, setOffers] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const [openPDF, setOpenPDF] = useState(false)
  const [openImg, setOpenImg] = useState(false)
  const [openCounterModal, setOpenCounterModal] = useState(false)
  const [isAccept, setIsAccept] = useState(true)
  const [location, setLocation] = useState<any>({})
  const [partner, setPartner] = useState<any>({})
  const [bidId, setBidId] = useState("")
  const { sfId: sfid } = useActiveUser()
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [pdf, setPDF] = useState("")
  const [loadingImges, setLoadingImges] = useState(false)
  const [images, setImages] = useState<any[]>([])

  const graphqlService = useGraphQLService()

  const getPartnerData = useCallback(
    (signal?: AbortSignal) => {
      graphqlService
        .getPartnerBySfid({ sfid }, signal)
        .then((response) => {
          setPartner(response)
        })
        .catch(() => {})
    },
    [graphqlService, sfid]
  )

  const getLocationData = useCallback(
    (id: any, signal?: AbortSignal) => {
      setLoadingLocation(true)
      setLoadingImges(true)
      graphqlService
        .getLocation({ siteId: id }, signal)
        .then((response) => {
          setLocation(response)
          setLoadingLocation(false)
          getImageData(response)
        })
        .catch(() => {
          setLoadingLocation(false)
          setLoadingImges(false)
        })
    },
    [graphqlService]
  )

  const loadData = useCallback(
    (sfid: any, signal?: AbortSignal) => {
      setLoading(true)
      setLoadingPDF(true)
      graphqlService
        .getBidDetails({ sfid: sfid }, signal)
        .then((response) => {
          let temporalOffers: any[] = []
          let details = response?.details
          details = details.sort((a: any, b: any) => {
            if (a.sort_order > b.sort_order) {
              return 1
            }
            if (a.sort_order < b.sort_order) {
              return -1
            }
            return 0
          })

          details.map((d: any) => {
            temporalOffers.push({
              sfid: d.sfid,
              newAmount: d.counter_offer_price
                ? Number(d.counter_offer_price)
                : d.counter_offer_price,
              counter: d.counter_offer_price
                ? Number(d.counter_offer_price)
                : d.counter_offer_price,
              caseOffer: Number(d.case_snow_offer),
              openField: false,
              active: !!d.counter_offer_price,
            })
          })
          setOffers(temporalOffers)
          setLoading(false)
          getLocationData(response?.site_id)
          groupDetails(response)
          getPDF(response)
        })
        .catch(() => {
          setLoading(false)
          setLoadingPDF(false)
        })
    },
    [getLocationData, graphqlService]
  )

  useEffect(() => {
    const controller = new AbortController()
    const id = getUrlParam("id") as string
    if (id) {
      setBidId(id)
      loadData(id, controller.signal)
      getPartnerData(controller.signal)
    } else {
      navigate("/bids")
    }
    return () => controller.abort()
  }, [getPartnerData, loadData, navigate])

  const handleDecline = useCallback(() => {
    setLoading(true)
    graphqlService
      .rejectBid({ sfid: bidId })
      .then(() => {
        loadData(bidId)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [bidId, graphqlService, loadData])

  const handlePDF = (isAccept: boolean) => {
    setOpenCounterModal(false)
    setIsAccept(isAccept)
    if (getExhibitB() === "") {
      if (isAccept) {
        acceptExhibit()
      } else {
        handleCounter()
      }
    } else {
      setOpen(true)
    }
  }

  const handleAcceptPDF = () => {
    if (isAccept) {
      acceptExhibit()
    } else {
      handleCounter()
    }
  }

  const handleCounter = useCallback(() => {
    setOpen(false)
    let coutners: any[] = []

    for (let group of details?.groupedDetails) {
      for (let d of group.details) {
        let offer = offers.find((o) => o.sfid === d.sfid)
        if (offer.newAmount > offer.caseOffer) {
          coutners.push({
            counter_id: offer.sfid,
            counter_amount: offer.newAmount,
          })
        } else {
          coutners.push({
            counter_id: offer.sfid,
            counter_amount: offer.caseOffer,
          })
        }
      }
    }

    setLoading(true)
    graphqlService
      .makeCounterBid({ sfid: bidId, counters: coutners })
      .then(() => {
        loadData(bidId)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [bidId, details?.groupedDetails, graphqlService, loadData, offers])

  const inputChanged = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    var removedText = e.target.value.replace(/\D+/g, "")
    setOffers((values) =>
      values.map((v, i) =>
        index === i
          ? {
              ...v,
              newAmount: Number(removedText),
            }
          : v
      )
    )
  }

  const openField = (index: any) => {
    for (let i = 0; i < details.groupedDetails[index].details.length; i++) {
      setOffers((values) =>
        values.map((v, j) =>
          details.groupedDetails[index].details[i].sfid === v.sfid
            ? {
                ...v,
                openField: true,
              }
            : v
        )
      )
    }
  }

  const closeField = (index: any) => {
    for (let i = 0; i < details.groupedDetails[index].details.length; i++) {
      setOffers((values) =>
        values.map((v, j) =>
          details.groupedDetails[index].details[i].sfid === v.sfid
            ? {
                ...v,
                openField: false,
                active: false,
                newAmount: null,
              }
            : v
        )
      )
    }
  }

  const isActive = (detail: any) => {
    let index = getOfferIndex(detail)
    return offers[index].active
  }

  const handleChangeActive = (detail: any) => {
    let index = getOfferIndex(detail)

    setOffers((values) =>
      values.map((v, i) =>
        index === i
          ? {
              ...v,
              active: !v.active,
              newAmount: null,
            }
          : v
      )
    )
  }

  const acceptExhibit = useCallback(() => {
    setOpen(false)
    setLoading(true)
    graphqlService
      .acceptBid({ sfid: bidId })
      .then(() => {
        loadData(bidId)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [bidId, graphqlService, loadData])

  const groupDetails = (data: any) => {
    if (
      data.bid_status == "No Response" &&
      isPastDate(data.contract_end_date)
    ) {
      data.bid_status = "Expired"
    }
    let scopes: any[] = []
    for (let d of data.details) {
      if (scopes.findIndex((s) => s === d.bid_details_title) === -1) {
        scopes.push(d.bid_details_title)
      }
    }

    let keys: any[] = []
    for (let d of data.details) {
      if (d.service_key !== null) {
        if (keys.findIndex((s) => s === d.service_key) === -1) {
          keys.push(d.service_key)
        }
      } else {
        if (keys.findIndex((s) => s === d.bid_details_title) === -1) {
          keys.push(d.bid_details_title)
        }
      }
    }

    let groupedDetails: any[] = []
    for (let s of scopes) {
      let scopeDetails: any[] = []
      for (let d of data.details) {
        if (d.bid_details_title === s) {
          scopeDetails.push(d)
        }
      }
      groupedDetails.push({ name: s, details: scopeDetails })
    }

    let groupedKeys: any[] = []
    for (let k of keys) {
      let keyDetails: any[] = []
      for (let d of data.details) {
        if (d.service_key === k) {
          keyDetails.push(d)
        }
      }
      groupedKeys.push({ name: k, details: keyDetails })
    }

    data.groupedDetails = groupedDetails.sort()
    data.groupedKeys = groupedKeys.sort()
    setDetails(data)
  }

  const makeOffer = (ff: any) => {
    let result = false
    ff.details.map((f: any) => {
      let a = offers.find((o: any) => o.sfid == f.sfid)
      result = result || a.openField
    })
    return result
  }

  const checkIncrement = (details: any) => {
    let check = false
    details.map((d: any) => {
      if (d.increment) {
        check = true
      }
    })
    return check
  }

  const getOfferIndex = (detail: any) => {
    let offer = offers.find((o: any) => o.sfid === detail.sfid)
    return offers.findIndex((o) => o === offer)
  }

  const isCounterDetail = (detail: any) => {
    let offer = offers.find((o) => o.sfid === detail.sfid)
    return offer.caseOffer < offer.newAmount
  }

  const isCounterUpdate = () => {
    let isCounterUpdate = false
    for (let group of details?.groupedDetails) {
      for (let d of group.details) {
        let offer = offers.find((o) => o.sfid === d.sfid)
        if (offer.caseOffer < offer.newAmount) {
          isCounterUpdate = true
        }
      }
    }

    return isCounterUpdate
  }

  const isCounter = (group: any) => {
    let isCounter = false

    for (let d of group.details) {
      let offer = offers.find((o) => o.sfid === d.sfid)
      if (offer.caseOffer < offer.newAmount) {
        isCounter = true
      }
    }

    return isCounter
  }

  const noResponseHasCounter = () => {
    let isCounterUpdate = false
    for (let group of details?.groupedDetails) {
      for (let d of group.details) {
        let offer = offers.find((o) => o.sfid === d.sfid)
        if (offer.caseOffer < offer.counter) {
          isCounterUpdate = true
        }
      }
    }

    return isCounterUpdate && details.bid_status === "No Response"
  }

  const getExhibitB = () => {
    let id = ""
    details?.exhibits?.map((e: any) => {
      if (e.title.indexOf("Exhibit B") >= 0) {
        id = e.sfid
      }
    })
    return id
  }

  const getPDF = (response: any) => {
    let sfid = ""
    response?.exhibits?.map((e: any) => {
      if (e.title.indexOf("Exhibit B") >= 0) {
        sfid = e.sfid
      }
    })

    imageService
      .getPDF({ sfid })
      .then((response) => {
        setPDF(response)
        setLoadingPDF(false)
      })
      .catch(() => {
        console.log("error PDF")
      })
  }

  const openImage = () => {
    setOpenImg(true)
  }

  const getImageData = useCallback(async (response: any) => {
    let totalImg = response?.attachments?.length
    if (!totalImg || !response?.sfid) {
      setLoadingImges(false)
      setImages([])
      return
    }
    const sfids = response?.attachments.map((a: any) => a.sfid)
    const imagesData = await imageHelper.getImages(sfids, response?.sfid)
    setImages(imagesData)
    setLoadingImges(false)
  }, [])

  const distance = () => {
    if (
      partner?.billinglatitude &&
      partner?.billinglongitude &&
      location?.billinglatitude &&
      location?.billinglongitude
    ) {
      let from = {
        lat: location?.billinglatitude,
        lon: location?.billinglongitude,
      }
      let to = {
        lat: partner?.billinglatitude,
        lon: partner?.billinglongitude,
      }
      return distanceInMiles(from, to)
    }
    return ""
  }

  if (loading || loadingLocation) {
    return <Loader />
  } else {
    return (
      <>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpen(false)}
        >
          <Box className={classes.style}>
            <div className={classes.iframeBlock}>
              {loadingPDF ? (
                <Loader />
              ) : (
                <iframe className="w-full h-full" src={pdf} />
              )}
            </div>
            <div className="flex flex-col items-center">
              <Button
                onClick={() => handleAcceptPDF()}
                style={{
                  marginTop: "80px",
                  margin: "auto",
                  marginBottom: "10px",
                }}
                className={classes.actionButton + " w-40"}
              >
                {i18n.t("bids.details.iAccept")}
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openCounterModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenCounterModal(false)}
        >
          <Box className={classes.counterModalStyle}>
            <div className={classes.counterModalMessage}>
              <p className="font-bold text-lg mb-4">Counter Offer Submission</p>
              <p>
                {i18n.t("bids.details.counterOfferMessage")}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <Button
                onClick={() => handlePDF(false)}
                style={{
                  marginTop: "80px",
                  margin: "auto",
                  marginBottom: "10px",
                }}
                className={classes.actionButton + " w-40"}
              >
                {i18n.t("bids.details.iAccept")}
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openPDF}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenPDF(false)}
        >
          <Box className={classes.style}>
            <div className={classes.iframeBlock}>
              {loadingPDF ? (
                <Loader />
              ) : (
                <iframe className="w-full h-full" src={pdf} />
              )}
            </div>
          </Box>
        </Modal>

        <Modal
          open={openImg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenImg(false)}
        >
          <Box className={classes.styleImg}>
            <div className={classes.iframeBlockImg}>
              <div className="p-8">
                <Carousel
                  animation={"slide"}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                  navButtonsProps={{
                    style: {},
                  }}
                >
                  {images?.map((img: any, i: any) => (
                    <div key={i} className="flex flex-col items-center">
                      {loadingImges ? (
                        <Loader />
                      ) : (
                        <img
                          className={classes.carouselImg}
                          alt="photo"
                          src={img}
                        />
                      )}
                    </div>
                  ))}
                </Carousel>
              </div>

              <Button
                variant="text"
                onClick={() => setOpenImg(false)}
                className={classes.imgButton}
              >
                <CloseIcon />
              </Button>
            </div>
          </Box>
        </Modal>
        <div>
          <Container className={classes.container}>
            <Grid container>
              <div className="flex flex-col gap-2 right-0 absolute top-0 px-6 items-end">
                <div className={`bg-[#10291B] p-2 rounded-lg mt-[10px] shadow-lg 
                ${!details.attentive_link ? 'opacity-30' : 'cursor-pointer'}`}
                  onClick={details.attentive_link ? ()=> window.open(details.attentive_link, '_blank') : undefined}>
                    <img alt={"attentive-logo"} src={attentiveLogo} />
                </div>

                <span onClick={()=> {
                    if(!details.attentive_link) return

                    window.open(details.attentive_link, '_blank')
                  }}
                  className={details.attentive_link ? "hover:cursor-pointer hover:font-bold hover:text-case-grad-mid hover:underline text-right cursor-pointer" : "font-bold opacity-20"}>{i18n.t("bids.details.attentiveClickMessage")}</span>
              </div>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={5}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={classes.showTitle}>
                          <div className="mb-4 ml-4 flex flex-row items-center justify-between">
                            <Typography
                              noWrap
                              className="flex flex-row items-center"
                            >
                              <span
                                className="cursor-pointer text-breadcrumb-blue font-bold"
                                onClick={() => navigate("/")}
                              >
                                {i18n.t("breadcrumbs.home")}
                              </span>
                              <RightIcon className="w-3.5 mr-3 ml-3" />
                              <span
                                className="cursor-pointer text-breadcrumb-blue font-bold"
                                onClick={() => navigate("/bids")}
                              >
                                {i18n.t("breadcrumbs.bidListing")}
                              </span>
                              <RightIcon className="w-3.5 mr-3 ml-3" />
                              <span className="font-bold">{i18n.t("breadcrumbs.bidDetails")}</span>
                            </Typography>
                          </div>
                          <div className=" ml-4">
                            <Typography noWrap>
                              <span className={classes.titleFont}>
                                {details.customer_name}
                              </span>
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <div className="ml-8">
                              <div className="mt-3">
                                <span className="underline">
                                  {details.bid_location_name}
                                </span>
                              </div>
                              <div className="mt-3">
                                <p>
                                  {i18n.t("common.tableHeaders.distance") + " "}
                                  <span className="font-bold">
                                    {distance()}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <div className={classes.pillStatus}>
                          <Typography
                            className={
                              details.bid_status === "Awarded"
                                ? classes.blueStatus
                                : details.bid_status === "Accepted"
                                ? classes.greenStatus
                                : details.bid_status === "Declined" ||
                                  details.bid_status === "Not Awarded" ||
                                  details.bid_status === "Terminated"
                                ? classes.redStatus
                                : details.bid_status === "No Response"
                                ? classes.newBidStatus
                                : details.bid_status === "Expired"
                                ? classes.expiredStatus
                                : classes.yellowStatus
                            }
                          >
                            <span className="capitalize">
                              {details.bid_status === "No Response"
                                ? i18n.t("bids.filterTypes.openBids")
                                : i18n.t(`bids.${details.bid_status.toLowerCase()}`)}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3} className={classes.detailsTop + " w-full"}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className={
                          classes.marginB + " flex flex-row items-center"
                        }
                      >
                        <CalendarIcon className="mr-3" />
                        <div>
                          <span className={classes.infoFont}>
                            {details?.contract_start_date
                              ? formatDateUTC(details?.contract_start_date)
                              : "N/A"}
                          </span>
                          <Typography noWrap className={classes.infoTitle}>
                            {i18n.t("bids.details.contractStartDate")}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={
                          classes.marginB + " flex flex-row items-center"
                        }
                      >
                        <CalendarIcon className="mr-3" />
                        <div>
                          <span className={classes.infoFont}>
                            {formatDateUTC(details?.contract_end_date)}
                          </span>
                          <Typography noWrap className={classes.infoTitle}>
                            {i18n.t("bids.details.contractEndDate")}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} className="flex flex-row items-center">
                        <ClipboardIcon className="mr-3" />
                        <div>
                          <span className={classes.infoFont}>
                            {details.bid_response_deadline
                              ? formatDateUTC(details.bid_response_deadline)
                              : "-"}
                          </span>
                          <Typography noWrap className={classes.infoTitle}>
                            {i18n.t("common.tableHeaders.bidDeadline")}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4} className={classes.detailsTop + " w-full"}>
                    <Grid container>
                      <Grid item xs={12} className="flex flex-row items-center">
                        <div className="w-full">
                          <div className={classes.imgContainer}>
                            {!!images?.length && (
                              <Carousel
                                animation={"slide"}
                                autoPlay={false}
                                navButtonsAlwaysVisible={true}
                                navButtonsProps={{
                                  style: {},
                                }}
                              >
                                {images.map((img: any, i: any) => (
                                  <div key={i}>
                                    {loadingImges ? (
                                      <Loader />
                                    ) : (
                                      <img
                                        className={
                                          classes.image + " cursor-pointer"
                                        }
                                        onClick={() => openImage()}
                                        alt="photo"
                                        src={img}
                                      />
                                    )}
                                  </div>
                                ))}
                              </Carousel>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.grid}>
                      <Grid container>
                        <Grid item xs={12}>
                          <div className={classes.serivcesOverviewTitle}>
                            {i18n.t("bids.details.serviceOverview")}
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div className="m-5">
                            <BidServiceMatrix details={details} />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="m-5">
                            <BidServiceDetails details={details} />
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <div className={classes.serivcesOverviewTitle}>
                            {i18n.t("bids.bidDetails")}
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          {(details.bid_status === "No Response" ||
                            details.bid_status === "Counter") && (
                            <div className={classes.buttons}>
                              {(!isCounterUpdate() ||
                                noResponseHasCounter()) && (
                                <Button
                                  onClick={() => handlePDF(true)}
                                  className={classes.actionButton + " w-40"}
                                  disabled={details.bid_status === "Counter"}
                                >
                                  {i18n.t("bids.details.acceptAll")}
                                </Button>
                              )}
                              {isCounterUpdate() && !noResponseHasCounter() && (
                                <Button
                                  onClick={() => setOpenCounterModal(true)}
                                  className={classes.actionButton + " w-40"}
                                  disabled={details.bid_status === "Counter"}
                                >
                                  {i18n.t("bids.details.submitOffer")}
                                </Button>
                              )}
                            </div>
                          )}
                        </Grid>

                        {details?.details &&
                          !!details?.details.length &&
                          details?.groupedDetails.map((g: any, index: any) => (
                            <React.Fragment key={index}>
                              <Grid item xs={12}>
                                <div className={classes.gridDetail}>
                                  <Grid container>
                                    <Grid item xs={2} className="flex">
                                      <div className="w-full text-left float-left ">
                                        <div
                                          className={
                                            (details.service_line === "Snow"
                                              ? classes.snow
                                              : details.service_line === "Land"
                                              ? classes.landscape
                                              : details.service_line ===
                                                "Sweeping"
                                              ? classes.sweeping
                                              : classes.janitorial) +
                                            " min-w-40 rounded flex flex-col items-center"
                                          }
                                        >
                                          <div
                                            className={classes.iconServiceLine}
                                          >
                                            <span className="text-white font-bold">
                                              {details.service_line ===
                                              "Snow" ? (
                                                <CloudSnowIcon />
                                              ) : details.service_line ===
                                                "Land" ? (
                                                <TreeIcon />
                                              ) : details.service_line ===
                                                "Sweeping" ? (
                                                <SweepingIcon />
                                              ) : (
                                                <JanitoralIcon />
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>

                                    <Grid item xs={4} className="flex">
                                      <Grid
                                        container
                                        className={classes.detailsCard}
                                      >
                                        <div className="flex flex-row items-center w-full ml-4"></div>

                                        <Grid item xs={8}>
                                          <div className="mr-3">
                                            {/* {isCounter(g) && (
                                              <div>
                                                <Typography noWrap>
                                                  <span
                                                    className={
                                                      classes.coutnerStatus
                                                    }
                                                  >
                                                    Counter
                                                  </span>
                                                </Typography>
                                              </div>
                                            )} */}
                                            <div
                                              className={classes.cardDetailName}
                                            >
                                              {i18n.t("common.tableHeaders.service")}
                                            </div>
                                            <div
                                              className={
                                                classes.cardDetailDescription
                                              }
                                            >
                                              {g.name}
                                            </div>
                                          </div>
                                        </Grid>

                                        <Grid item xs={4}>
                                          {g?.details[0].frequency && (
                                            <div>
                                              <div
                                                className={
                                                  classes.cardDetailName
                                                }
                                              >
                                                {i18n.t("common.tableHeaders.frequency")}
                                              </div>
                                              <div
                                                className={
                                                  classes.cardDetailDescription
                                                }
                                              >
                                                {g?.details[0].frequency}
                                              </div>
                                            </div>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={4}
                                      className={
                                        "flex " +
                                        (checkIncrement(g?.details)
                                          ? ""
                                          : classes.fixCard)
                                      }
                                    >
                                      {!makeOffer(g) &&
                                        details.bid_status === "No Response" &&
                                        !noResponseHasCounter() && (
                                          <Grid
                                            container
                                            className={classes.detailsCard}
                                          >
                                            <Grid item xs={12}>
                                              <div className="flex flex-row items-center w-full h-full">
                                                {checkIncrement(g?.details) &&
                                                  g?.details.length === 1 && (
                                                    <Grid item xs={6}>
                                                      <div
                                                        className={
                                                          "min-w-40 rounded flex flex-col items-center"
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            classes.cardIncrementCounter
                                                          }
                                                        >
                                                          {i18n.t("bids.details.increment")}
                                                        </div>
                                                        {g?.details &&
                                                          g?.details.map(
                                                            (
                                                              detail: any,
                                                              i: any
                                                            ) => (
                                                              <React.Fragment
                                                                key={i}
                                                              >
                                                                {detail.increment && (
                                                                  <div
                                                                    className={
                                                                      classes.cardDetailDescription
                                                                    }
                                                                  >
                                                                    {
                                                                      detail.increment
                                                                    }
                                                                  </div>
                                                                )}
                                                              </React.Fragment>
                                                            )
                                                          )}
                                                      </div>
                                                    </Grid>
                                                  )}

                                                <Grid
                                                  item
                                                  xs={
                                                    checkIncrement(
                                                      g?.details
                                                    ) && g?.details.length >= 1
                                                      ? 6
                                                      : 12
                                                  }
                                                >
                                                  {details.bid_status ===
                                                    "No Response" && (
                                                    <div className="flex flex-col items-end w-full">
                                                      <Button
                                                        variant="text"
                                                        onClick={() =>
                                                          openField(index)
                                                        }
                                                      >
                                                        <span className="outlined underline">
                                                          {i18n.t("bids.details.makeAnOffer")}
                                                        </span>
                                                      </Button>
                                                    </div>
                                                  )}
                                                </Grid>

                                                {checkIncrement(g?.details) &&
                                                  g?.details.length > 1 && (
                                                    <Grid item xs={6}>
                                                      <div
                                                        className={
                                                          "min-w-40 rounded flex flex-col items-center"
                                                        }
                                                      >
                                                        <span
                                                          className={
                                                            classes.cardIncrementCounter
                                                          }
                                                        >
                                                          {i18n.t("bids.details.increment").toUpperCase()}
                                                        </span>
                                                        {g?.details &&
                                                          g?.details.map(
                                                            (
                                                              detail: any,
                                                              index: any
                                                            ) => (
                                                              <React.Fragment
                                                                key={index}
                                                              >
                                                                {detail.increment && (
                                                                  <>
                                                                    <span className="mb-1.5 mt-1.5">
                                                                      {
                                                                        detail.increment
                                                                      }
                                                                    </span>
                                                                    {g?.details
                                                                      .length !==
                                                                      index +
                                                                        1 && (
                                                                      <Divider
                                                                        variant="middle"
                                                                        className="w-1/2"
                                                                      />
                                                                    )}
                                                                  </>
                                                                )}
                                                              </React.Fragment>
                                                            )
                                                          )}
                                                      </div>
                                                    </Grid>
                                                  )}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        )}

                                      {((!makeOffer(g) &&
                                        details.bid_status !== "No Response") ||
                                        noResponseHasCounter()) && (
                                        <Grid
                                          container
                                          className={classes.detailsCard}
                                        >
                                          <Grid item xs={12}>
                                            <div className="flex flex-row items-center w-full h-full">
                                              <Grid item xs={2}>
                                                <div
                                                  className={
                                                    "min-w-40 rounded flex flex-col items-center"
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      classes.cardIncrementCheck
                                                    }
                                                  >
                                                    .
                                                  </span>
                                                  {g?.details &&
                                                    g?.details.map(
                                                      (detail: any, i: any) => (
                                                        <div
                                                          key={i}
                                                          className="flex flex-col items-center my-3"
                                                        >
                                                          {isCounterDetail(
                                                            detail
                                                          ) ? (
                                                            <LogInIcon />
                                                          ) : (
                                                            <CheckIcon />
                                                          )}
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                              </Grid>

                                              {checkIncrement(g?.details) && (
                                                <Grid item xs={5}>
                                                  <div
                                                    className={
                                                      "min-w-40 rounded flex flex-col items-center"
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        classes.cardIncrementCounter
                                                      }
                                                    >
                                                      {i18n.t("bids.details.increment").toUpperCase()}
                                                    </span>
                                                    {g?.details &&
                                                      g?.details.map(
                                                        (
                                                          detail: any,
                                                          index: any
                                                        ) => (
                                                          <React.Fragment
                                                            key={index}
                                                          >
                                                            {detail.increment && (
                                                              <>
                                                                <span className="mb-1.5 mt-1.5">
                                                                  {
                                                                    detail.increment
                                                                  }
                                                                </span>
                                                                {g?.details
                                                                  .length !==
                                                                  index + 1 && (
                                                                  <Divider
                                                                    variant="middle"
                                                                    className="w-1/2"
                                                                  />
                                                                )}
                                                              </>
                                                            )}
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                  </div>
                                                </Grid>
                                              )}

                                              <Grid
                                                item
                                                xs={
                                                  checkIncrement(g?.details)
                                                    ? 5
                                                    : 10
                                                }
                                              >
                                                <div
                                                  className={
                                                    "min-w-40 rounded flex flex-col items-center"
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      classes.cardIncrementCounter
                                                    }
                                                  >
                                                    {(!isCounter(g)
                                                      ? i18n.t("bids.details.accepted")
                                                      : i18n.t("bids.details.counter")).toUpperCase()}
                                                  </span>
                                                  {g?.details &&
                                                    g?.details.map(
                                                      (
                                                        detail: any,
                                                        index: any
                                                      ) => (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          {
                                                            <>
                                                              <span className="mb-1.5 mt-1.5">
                                                                $
                                                                {isCounterDetail(
                                                                  detail
                                                                )
                                                                  ? decimalNumber(detail.counter_offer_price)
                                                                  : decimalNumber(detail.case_snow_offer)}
                                                              </span>
                                                              {g?.details
                                                                .length !==
                                                                index + 1 && (
                                                                <Divider
                                                                  variant="middle"
                                                                  className="w-1/2"
                                                                />
                                                              )}
                                                            </>
                                                          }
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                </div>
                                              </Grid>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      )}

                                      {makeOffer(g) && !noResponseHasCounter() && (
                                        <div
                                          className={
                                            classes.counterCard +
                                            " flex flex-row items-center " +
                                            (checkIncrement(g?.details)
                                              ? "w-full"
                                              : "w-1/2")
                                          }
                                        >
                                          {details.bid_status ===
                                            "No Response" && (
                                            <Button
                                              variant="text"
                                              onClick={() => closeField(index)}
                                              className={classes.closeOffer}
                                            >
                                              <CloseBIcon />
                                            </Button>
                                          )}

                                          {details.bid_status ===
                                            "No Response" && (
                                            <Grid item xs={1}>
                                              <div
                                                className={
                                                  "flex flex-col items-center mt-6"
                                                }
                                              >
                                                {g?.details.length > 1 &&
                                                  g?.details &&
                                                  g?.details.map(
                                                    (detail: any, i: any) => (
                                                      <div
                                                        key={i}
                                                        className="w-full flex flex-row items-center "
                                                      >
                                                        <Checkbox
                                                          size="small"
                                                          checked={isActive(
                                                            detail
                                                          )}
                                                          disabled={
                                                            !(
                                                              details.bid_status ===
                                                              "No Response"
                                                            )
                                                          }
                                                          onChange={() =>
                                                            handleChangeActive(
                                                              detail
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            </Grid>
                                          )}

                                          {checkIncrement(g?.details) && (
                                            <Grid item xs={5}>
                                              <div
                                                className={
                                                  "min-w-40 rounded flex flex-col items-center"
                                                }
                                              >
                                                <span
                                                  className={
                                                    classes.cardIncrementCounter
                                                  }
                                                >
                                                  {i18n.t("bids.details.increment").toUpperCase()}
                                                </span>
                                                {g?.details &&
                                                  g?.details.map(
                                                    (detail: any, i: any) => (
                                                      <React.Fragment key={i}>
                                                        {
                                                          <div className="w-11/12 flex flex-row items-center">
                                                            <div className="w-11/12 flex flex-col items-center">
                                                              {checkIncrement(
                                                                g?.details
                                                              ) && (
                                                                <span className="mb-1.5 mt-1.5">
                                                                  {
                                                                    detail.increment
                                                                  }
                                                                </span>
                                                              )}
                                                              {checkIncrement(
                                                                g?.details
                                                              ) &&
                                                                g?.details
                                                                  .length !==
                                                                  index + 1 && (
                                                                  <Divider
                                                                    variant="middle"
                                                                    className="w-full"
                                                                  />
                                                                )}
                                                            </div>
                                                          </div>
                                                        }
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </div>
                                            </Grid>
                                          )}

                                          <Grid
                                            item
                                            xs={
                                              checkIncrement(g?.details)
                                                ? 6
                                                : 11
                                            }
                                          >
                                            <div
                                              className={
                                                "min-w-40 rounded flex flex-col items-center"
                                              }
                                            >
                                              <span
                                                className={
                                                  classes.cardIncrementCounter
                                                }
                                              >
                                                {i18n.t("bids.details.counter").toUpperCase()}
                                              </span>
                                              {g?.details &&
                                                g?.details.map(
                                                  (detail: any, jndex: any) => (
                                                    <div
                                                      key={jndex}
                                                      className="flex flex-row items-center"
                                                    >
                                                      {isActive(detail) ||
                                                      g?.details.length ===
                                                        1 ? (
                                                        <TextField
                                                          id={
                                                            "outlined-helperText"
                                                          }
                                                          variant={"outlined"}
                                                          size="small"
                                                          disabled={
                                                            !(
                                                              details.bid_status ===
                                                              "No Response"
                                                            )
                                                          }
                                                          onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                          ) =>
                                                            inputChanged(
                                                              e,
                                                              getOfferIndex(
                                                                detail
                                                              )
                                                            )
                                                          }
                                                          value={
                                                            offers[
                                                              getOfferIndex(
                                                                detail
                                                              )
                                                            ].newAmount
                                                          }
                                                          type="text"
                                                          style={{
                                                            backgroundColor:
                                                              "#FFF",
                                                            border: "none",
                                                            borderRadius: "5px",
                                                          }}
                                                          className={
                                                            classes.counterInput
                                                          }
                                                        />
                                                      ) : (
                                                        <div className="h-9">
                                                          -
                                                        </div>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          </Grid>
                                        </div>
                                      )}
                                    </Grid>

                                    <Grid item xs={2} className="flex">
                                      <div className="flex flex-row items-center w-full">
                                        <Grid item xs={12} className="h-full">
                                          <div className="w-full text-left float-left h-full">
                                            <div
                                              className={
                                                (details.service_line === "Snow"
                                                  ? classes.snow
                                                  : details.service_line ===
                                                    "Land"
                                                  ? classes.landscape
                                                  : details.service_line ===
                                                    "Sweeping"
                                                  ? classes.sweeping
                                                  : classes.janitorial) +
                                                " min-w-40 rounded flex flex-col items-center"
                                              }
                                            >
                                              <span className="font-bold mb-3">
                                                {i18n.t("bids.details.caseOffer").toUpperCase()}
                                              </span>
                                              {g?.details &&
                                                g?.details.map(
                                                  (detail: any, i: any) => (
                                                    <span
                                                      key={i}
                                                      className="text-2xl font-bold mb-3.5 flex flex-row items-center h-2/5"
                                                    >
                                                      ${decimalNumber(detail.case_snow_offer)}
                                                    </span>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </Grid>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </React.Fragment>
                          ))}

                        <Grid item xs={12}>
                          {(details.bid_status === "No Response" ||
                            details.bid_status === "Counter") && (
                            <div className={classes.buttons}>
                              {(!isCounterUpdate() ||
                                noResponseHasCounter()) && (
                                <Button
                                  onClick={() => handlePDF(true)}
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  className={classes.actionButton + " w-40"}
                                  disabled={details.bid_status === "Counter"}
                                >
                                  {i18n.t("bids.details.acceptAll")}
                                </Button>
                              )}
                              {isCounterUpdate() && !noResponseHasCounter() && (
                                <Button
                                  onClick={() => setOpenCounterModal(true)}
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  className={classes.actionButton + " w-40"}
                                  disabled={details.bid_status === "Counter"}
                                >
                                  {i18n.t("bids.details.submitCounter")}
                                </Button>
                              )}
                              <Button
                                onClick={handleDecline}
                                className={classes.declineButton + " w-40"}
                                disabled={details.bid_status === "Counter"}
                              >
                                {i18n.t("bids.details.declineAll")}
                              </Button>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.grid}>
                      <Grid container>
                        <Grid item xs={12}>
                          <div>
                            <div className={classes.serivcesOverviewTitle}>
                              {i18n.t("bids.details.additionalInformation")}
                            </div>
                            <div className="ml-10 mt-4 mb-4">
                              <p>{details?.exhibit_c_verbiage__c || "-"}</p>
                            </div>
                          </div>
                        </Grid>
                        {getExhibitB() !== "" && (
                          <Grid item xs={12}>
                            <div
                              className="flex flex-row items-center ml-10 pointer"
                              onClick={() => setOpenPDF(true)}
                            >
                              <PDFIcon className="mr-3" />
                              <span className={classes.customerContract}>
                                {i18n.t("bids.details.customerContract")}
                              </span>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    showTitle: {
      width: "auto",
      marginTop: "10px",
      marginLeft: "10px",
    },
    pillStatus: {
      width: "auto",
      marginTop: "10px",
    },
    titleFont: {
      fontSize: "44px",
      fontWeight: 800,
      lineHeight: "113%",
      color: "#424242",
      letterSpacing: "-1px",
    },
    secondaryTitleFont: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginTop: "10px",
      maxWidth: "100vw",
    },
    cardTitle: {
      fontWeight: 800,
      lineHeight: "113%",
      color: "#232F64",
      letterSpacing: "-1px",
      marginLeft: "40px",
      marginTop: "21px",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      background: "#FFFFFF",
      padding: "15px",
      margin: "10px 20px",
    },
    gridOffer: {
      borderRadius: "5px",
      margin: "5px",
      background: "#4ec7f1",
      padding: "15px",
    },
    container: {
      maxWidth: "100%",
      padding: 0,
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 20,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    direction: {
      marginTop: "0.7rem",
      marginBottom: "0.7rem",
    },
    distance: {
      color: "white",
    },
    tab: {
      marginTop: "30px",
    },
    buttonTab: {
      color: "#0F2150",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    selectedTab: {
      borderBottom: "2px solid #0F2150",
    },
    detailsTop: {
      marginTop: "40px",
    },
    greenStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    coutnerStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "#ffd978",
      color: "#665730",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    newBidStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "#fff",
      color: "#3d7556",
      border: "solid 1px #3d7556",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    expiredStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "gray",
      color: "black",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    blueStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "#4ec7f1",
      color: "#22586a",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    redStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    yellowStatus: {
      width: "200px",
      fontSize: "26px!important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "5px 15px",
      backgroundColor: "#ffd978",
      color: "#665730",
      "font-weight": "bold!important",
      textAlign: "right",
    },
    gridSerivceDetails1: {
      borderRadius: "5px",
      backgroundImage: "linear-gradient(135deg, #0F2150, #4EC7F1)",
      padding: "2px",
    },
    gridSerivceDetails2: {
      borderRadius: "5px",
      padding: "15px",
      backgroundColor: "#FFF",
    },
    data: {
      paddingLeft: "10px",
      paddingBottom: "20px",
    },
    serivcesOverviewTitle: {
      fontWeight: "bold",
      fontSize: "40px",
      color: "#0F2150",
      marginLeft: "40px",
    },
    cardDetailsTitle: {
      fontWeight: "bold",
      fontSize: "25px",
      marginLeft: "10px",
      color: "#0F2150",
    },
    cardDetails: {
      fontWeight: "bold",
      color: "#0F2150",
    },
    cardDetailsSub: {
      fontSize: "10px",
    },
    gridDetail: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "10px",
      background: "#FFFFFF",
      boxShadow: "0 2px 2px 0 rgb(0 0 0 / 20%)",
    },
    cardDetailInput: {
      width: "20%",
      marginRight: "20px",
    },
    cardDetailName: {
      color: "gray",
      fontWeight: "bold",
    },
    cardIncrementCounter: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: "13px",
    },
    cardIncrementCheck: {
      color: "white",
      fontWeight: "bold",
      fontSize: "13px",
    },
    cardDetailDescription: {
      fontWeight: "bold",
    },
    buttons: {
      display: "flex",
      justifyContent: "end",
      marginTop: "20px",
      marginBottom: "20px",
      marginRight: "10px",
    },
    snow: {
      background:
        "linear-gradient(-45grad, #a0e0f8 0%, #a0e0f8 50%, #95ddf7 50%, #95ddf7 100%)",
      color: "#0F2150",
      height: "100%",
    },
    landscape: {
      background:
        "linear-gradient(-45grad, #08543e 0%, #08543e 50%, #005c42 50%, #005c42 100%)",
      color: "white",
      height: "100%",
    },
    janitorial: {
      background:
        "linear-gradient(-45grad, #ffe299 0%, #ffe299 50%, #ffe9b3 50%, #ffe9b3 100%)",
      color: "white",
      height: "100%",
    },
    sweeping: {
      background:
        "linear-gradient(-45grad, #ffd1da 0%, #ffd1da 50%, #ffccd6 50%, #ffccd6 100%)",
      color: "white",
      height: "100%",
    },
    style: {
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, 2.5%)",
      backgroundColor: "white",
      borderRadius: "5px",
      width: "80%",
      minWidth: "1000px",
      height: "95%",
    },
    counterModalStyle: {
      position: "absolute" as "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "5px",
    },
    styleImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    iframeBlock: {
      height: "93%",
      padding: "5px",
    },
    iframeBlockImg: {
      width: "60%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    counterModalMessage: {
      padding: "20px",
    },
    marginB: {
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "auto",
    },
    imgContainer: {
      position: "relative",
      width: "100%",
      maxWidth: "200px",
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    detailsCard: {
      margin: "20px",
    },
    iconServiceLine: {
      margin: "20px",
      height: "100%",
      display: "flex",
      alignItems: " center",
      flexDirection: "row",
    },
    counterInput: {
      margin: "5px",
      width: "80px",
      "& .MuiInputBase-input": {
        padding: "5px",
      },
    },
    counterCard: {
      backgroundColor: "#f1fbfe",
    },
    closeOffer: {
      top: "calc(-50% + 15px)",
      cursor: "pointer",
      height: "25px",
      position: "relative",
      minWidth: "25px",
      background: "#f1fbfe",
      borderRadius: "5px",
    },
    customerContract: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#232F64",
      cursor: "pointer",
    },
    actionButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#0F2150",
      color: "#fff",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
      "&:hover": {
        backgroundColor: "#1b3a8c",
      },
    },
    declineButton: {
      border: "1px solid #0F2150",
      borderRadius: "100px",
      backgroundColor: "#fff",
      color: "#0F2150",
      "&.Mui-disabled": {
        border: "1px solid #e2e5ea",
        backgroundColor: "#e2e5ea",
        color: "#b2b7c6",
      },
    },
    fixCard: {
      display: "flex",
      justifyContent: "end",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
  })
)

export default BidDetailsDesktop
