import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  makeStyles,
  createStyles,
  Container,
  Grid,
  Typography,
  Button,
} from "@material-ui/core"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import Pagination from "../../../common/Pagination"
import { Loader } from "../../../common/Loader"
import { ReactComponent as UpIcon } from "./../../../../assets/up.svg"
import { ReactComponent as DownIcon } from "./../../../../assets/down.svg"
import { ReactComponent as WorkordenIcon } from "./../../../../assets/menu-orders-b.svg"
import { ReactComponent as CalendarIcon } from "./../../../../assets/calendar-b.svg"
import { ReactComponent as ClockIcon } from "./../../../../assets/clock-b.svg"
import { ReactComponent as HourglassIcon } from "./../../../../assets/hourglass-b.svg"
import { ReactComponent as ErrorIcon } from "./../../../../assets/circle-red.svg"
import { ReactComponent as MessageIcon } from "./../../../../assets/message-text.svg"
import { ReactComponent as CloseIcon } from "./../../../../assets/close-w.svg"
import { formatDate, getMinFormat, getTime } from "../../../../utils/Util"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Carousel from "react-material-ui-carousel"
import { useActiveUser } from "../../../../providers/AuthProvider"
import { useGraphQLService } from "../../../../services/graphqlService"
import { useFetchImages } from "../../../../utils/ImageHelper"
import { DateTime } from "luxon"
import useI18n from "../../../../hooks/useI18n"

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const WorkOrdersList = ({ filter, ...props }: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const graphqlService = useGraphQLService()
  const imageHelper = useFetchImages()

  const [search, searchInput] = useState("")
  const [data, setData] = useState<any[]>([])
  const [sort, setSort] = useState({ field: "service_date", order: "DESC" })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { sfId: sfid } = useActiveUser()
  const [showDetailIndex, setShowDetailIndex] = useState(-1)

  const [details, setDetails] = useState<any>({})
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [openImg, setOpenImg] = useState(false)
  const prevFilter = usePrevious({ filter, perPage })
  const [loadingImges, setLoadingImges] = useState(false)
  const [images, setImages] = useState<any[]>([])
  const { i18n } = useI18n()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f9f9f9",
      color: "#232F64",
      fontSize: "20px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  useEffect(() => {
    const controller = new AbortController()
    if (
      (filter !== prevFilter?.filter || perPage !== prevFilter?.perPage) &&
      page !== 1
    ) {
      setPage(1)
    } else {
      if (props.siteId) {
        setLoading(true)
        setDetails({})
        setShowDetailIndex(-1)
        graphqlService
          .getWorkOrders(
            {
              providerId: sfid,
              limit: perPage,
              offset: (page - 1) * perPage,
              sort: sort.field,
              order: sort.order,
              siteId: props.siteId,
              search: filter?.search,
            },
            controller.signal
          )
          .then((response) => {
            setData(response.worklogs || [])
            setTotal(response.pagination.found_rows)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }

      return () => controller.abort()
    }
  }, [graphqlService, page, perPage, filter, props.siteId, sfid, sort])

  const sortHandler = (by: any) => {
    if (by == sort.field) {
      if (sort.order === "ASC") {
        setSort({ field: sort.field, order: "DESC" })
      } else {
        setSort({ field: sort.field, order: "ASC" })
      }
    } else {
      setSort({ field: by, order: sort.order })
    }
  }

  const showDetail = useCallback(
    (sfid: any, index: any) => {
      if (index >= 0) {
        setDetails({})
        setLoadingDetails(true)
        setLoadingImges(true)
        setShowDetailIndex(index)
        graphqlService
          .getWorkLogDetails({
            sfid,
          })
          .then((response) => {
            setDetails(response)
            setLoadingDetails(false)
            getImageData(response)
          })
          .catch(() => {
            setLoading(false)
            setLoadingImges(false)
          })
      } else {
        setShowDetailIndex(index)
        setDetails({})
      }
    },
    [graphqlService]
  )

  const openImage = () => {
    setOpenImg(true)
  }

  const getImageData = useCallback(async (response: any) => {
    let totalImg = response?.attachments?.length
    if (!totalImg) {
      setLoadingImges(false)
      setImages([])
      return
    }
    const sfids = response?.attachments.map((a: any) => a.sfid)
    const imagesData = await imageHelper.getImages(sfids)
    setImages(imagesData)
    setLoadingImges(false)
  }, [])

  if (error) {
    return <p>ERROR: {error}</p>
  } else {
    return (
      <>
        <Modal
          open={openImg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setOpenImg(false)}
        >
          <Box className={classes.styleImg}>
            <div className={classes.iframeBlock}>
              <div className="p-8">
                <Carousel
                  animation={"slide"}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                  navButtonsProps={{
                    style: {},
                  }}
                  height={"85vh"}
                >
                  {!!images?.length &&
                    images.map((img: any, i: any) => (
                      <div key={i} className="flex flex-col items-center">
                        {loadingImges ? (
                          <Loader />
                        ) : (
                          <img
                            className={classes.carouselImg}
                            alt="photo"
                            src={img}
                          />
                        )}
                      </div>
                    ))}
                </Carousel>
              </div>

              <Button
                variant="text"
                onClick={() => setOpenImg(false)}
                className={classes.imgButton}
              >
                <CloseIcon />
              </Button>
            </div>
          </Box>
        </Modal>
        <div className={classes.listContainer}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    onClick={() => sortHandler("name")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center">
                      {sort.field === "name" && sort.order === "DESC" && (
                        <DownIcon />
                      )}
                      {sort.field === "name" && sort.order === "ASC" && (
                        <UpIcon />
                      )}
                      <span>{i18n.t("common.tableHeaders.workOrderNo")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("service_summary")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center">
                      {sort.field === "service_summary" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_summary" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.serviceSummary")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("service_date")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center">
                      {sort.field === "service_date" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_date" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.dateReported")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("service_date")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center">
                      {sort.field === "service_date" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "service_date" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.checkinTime")}</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => sortHandler("time_taken_minutes")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="flex flex-row items-center">
                      {sort.field === "time_taken_minutes" &&
                        sort.order === "DESC" && <DownIcon />}
                      {sort.field === "time_taken_minutes" &&
                        sort.order === "ASC" && <UpIcon />}
                      <span>{i18n.t("common.tableHeaders.timeTakenOnSite")}</span>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {!loading && !!data.length && (
                <TableBody>
                  {data.map((row: any, index: any) => (
                    <React.Fragment key={index}>
                      <StyledTableRow
                        hover
                        key={row.sfid}
                        onClick={() =>
                          showDetail(
                            row.sfid,
                            index === showDetailIndex ? -1 : index
                          )
                        }
                      >
                        <StyledTableCell>
                          <div className="flex flex-row items-center">
                            <span className={classes.paddingRight}>
                              {row.name}
                            </span>
                            <span>
                              {row.cancelled ? (
                                <ErrorIcon className="w-2" />
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>{row.service_summary}</StyledTableCell>
                        <StyledTableCell>
                          {row.service_date
                            ? formatDate(row.service_date)
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.service_date ? getTime(row?.service_date) : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.time_taken_minutes
                            ? getMinFormat(row.time_taken_minutes)
                            : "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={12}>
                          <Collapse
                            in={index === showDetailIndex}
                            timeout={100}
                            unmountOnExit
                          >
                            {loadingDetails ? (
                              <Loader />
                            ) : (
                              <div className="bg-case-gray">
                                <Container className={classes.container}>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <div className={classes.gridHeight}>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Typography
                                              className={classes.titleFont}
                                            >
                                              Work Orders Details
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            className="flex flex-row items-center mt-8"
                                          >
                                            <WorkordenIcon className="mr-3" />
                                            <div>
                                              <span
                                                className={classes.infoFont}
                                              >
                                                {details?.name}
                                              </span>
                                              <Typography
                                                noWrap
                                                className={classes.infoTitle}
                                              >
                                                Work Order #
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            className="flex flex-row items-center mt-8"
                                          >
                                            <CalendarIcon className="mr-3" />
                                            <div>
                                              <span
                                                className={classes.infoFont}
                                              >
                                                {details?.service_date
                                                  ? formatDate(
                                                      details?.service_date
                                                    )
                                                  : "-"}
                                              </span>
                                              <Typography
                                                noWrap
                                                className={classes.infoTitle}
                                              >
                                                Service Date
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            className="flex flex-row items-center mt-8"
                                          >
                                            <ClockIcon className="mr-3" />
                                            <div>
                                              <span
                                                className={classes.infoFont}
                                              >
                                                {details?.service_date
                                                  ? DateTime.fromISO(
                                                      details?.service_date
                                                    ).toFormat("t")
                                                  : "-"}
                                              </span>
                                              <Typography
                                                noWrap
                                                className={classes.infoTitle}
                                              >
                                                Service Time
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            className="flex flex-row items-center mt-8"
                                          >
                                            <HourglassIcon className="mr-3" />
                                            <div>
                                              <span
                                                className={classes.infoFont}
                                              >
                                                {details?.time_taken_minutes
                                                  ? getMinFormat(
                                                      details?.time_taken_minutes
                                                    )
                                                  : "-"}
                                              </span>
                                              <Typography
                                                noWrap
                                                className={classes.infoTitle}
                                              >
                                                Duration on-site
                                              </Typography>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div className={classes.gridHeight}>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Typography
                                              className={classes.titleFont}
                                            >
                                              Services Logged
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <div className=" m-3">
                                              {!!details?.services?.length &&
                                                details?.services.map(
                                                  (
                                                    service: any,
                                                    index: any
                                                  ) => (
                                                    <div
                                                      key={index}
                                                      className="font-bold flex flex-row items-center"
                                                    >
                                                      <div className="w-full">
                                                        {service?.service_key ||
                                                          "-"}
                                                        {" / "}
                                                        {service?.status}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            className="flex flex-row items-center mt-8"
                                          >
                                            <MessageIcon className="mr-3" />
                                            <div>
                                              <span
                                                className={classes.comments}
                                              >
                                                Comments
                                              </span>
                                            </div>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            className="flex flex-row items-center mt-8"
                                          >
                                            <div>
                                              <span
                                                className={classes.comments}
                                              >
                                                {details?.comments || "-"}
                                              </span>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <div className={classes.grid}>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Typography
                                              className={classes.titleFont}
                                            >
                                              Photos
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            className={classes.scrollableImg}
                                          >
                                            <div className="flex flex-row items-center">
                                              {loadingImges ? (
                                                <Loader />
                                              ) : (
                                                <>
                                                  {!!images?.length &&
                                                    images.map(
                                                      (
                                                        img: any,
                                                        index: any
                                                      ) => (
                                                        <div
                                                          key={index}
                                                          className={
                                                            classes.imgContainer
                                                          }
                                                        >
                                                          <img
                                                            className={
                                                              classes.image +
                                                              " cursor-pointer"
                                                            }
                                                            onClick={() =>
                                                              openImage()
                                                            }
                                                            alt="photo"
                                                            src={img}
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                </>
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Container>
                              </div>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {loading && <Loader />}
          {!loading && !data.length && (
            <div className="text-center">
              <div className="inline-block">
                <p>EMPTY</p>
              </div>
            </div>
          )}

          <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            total={total}
          />
        </div>
      </>
    )
  }
}
const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      width: "auto",
      marginLeft: 0,
      padding: "10px",
    },
    location: {
      fontWeight: "bold",
      color: "#1695CB",
    },
    greenStatus: {
      backgroundColor: "#d7f5e3",
      color: "#4e996c",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    redStatus: {
      backgroundColor: "#fed5d5",
      color: "#ff3b5f",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    yellowStatus: {
      backgroundColor: "#ffd978",
      color: "#665730",
      borderRadius: "20px",
      padding: "5px 10px",
      fontSize: "10px",
    },
    container: {
      maxWidth: "100%",
    },
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
    },
    titleFont: {
      fontSize: "20px!important",
      "font-weight": "800!important",
      lineHeight: "113%!important",
      color: "#0F2150!important",
      letterSpacing: "-1px!important",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
    },
    contactFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 13,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    image: {
      maxHeight: "100px",
    },
    imgContainer: {
      position: "relative",
      maxWidth: "150px",
      maxHeight: "100px",
      margin: "0px 5px 5px 0px",
      minWidth: "fit-content",
    },
    scrollableImg: {
      width: "500px",
      overflowY: "hidden",
    },
    styleImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    iframeBlock: {
      width: "60%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    gridHeight: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      padding: "5px",
      background: "#FFFFFF",
      minHeight: "95.5%",
    },
    commentsTitle: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
      marginLeft: "0px",
    },
    comments: {
      fontSize: 15,
      marginLeft: "10px",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
    paddingRight: {
      paddingRight: "1px",
    },
  })
)

export default WorkOrdersList
