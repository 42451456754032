import React, { useCallback, useEffect, useState } from "react"
import logo from "./../../assets/logo.svg"
import { Button, TextField } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import {
  checkIfIsAppLink,
  getUrlParam,
  transformPhoneNumber,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/Util"
import { useAuth } from "../../providers/AuthProvider"
import Stores from "./Stores"

const Login = () => {
  const navigate = useNavigate()
  const { checkAuth, login, sendEmail, sendSms, isAuthenticated } = useAuth()
  const [loggingIn, setLoggingIn] = useState(false)
  const [message, setMessage] = useState(false)
  const [noPermissionsMessage, setNoPermissionsMessage] = useState(false)
  const [input, setInput] = useState("")
  const [validInput, setValidInput] = useState<Boolean>(true)
  const [appLink, setAppLink] = useState<Boolean>(false)

  useEffect(() => {
    const controller = new AbortController()
    const token = getUrlParam("token") as string
    const email = getUrlParam("email") as string
    const phoneNumber = getUrlParam("phone_number") as string
    const isAppLink = checkIfIsAppLink()
    if (!isAppLink) {
      const doWork = async () => {
        setLoggingIn(true)
        if (!!token && (!!email || !!phoneNumber)) {
          await login(
            !!email
              ? { type: "email", email, token }
              : { type: "sms", phoneNumber, token },
            controller.signal
          )
          setNoPermissionsMessage(true)
        } else {
          if (!!email || !!phoneNumber) {
            setInput(email ?? phoneNumber)
          }
          setLoggingIn(await checkAuth(controller.signal))
        }
      }

      doWork().catch((e) => {
        console.error("[Login] error: ", e)
      })

      return () => controller.abort()
    } else {
      setAppLink(true)
    }
  }, [checkAuth, login])

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  const handleLogin = useCallback(
    async (e: any) => {
      e.preventDefault()

      const vemail = validateEmail(input)
      const vphone = validatePhoneNumber(input)
      setValidInput(vemail || vphone)

      if (vemail) {
        setMessage(true)
        await sendEmail(input.toLowerCase())
        return
      }

      if (vphone) {
        let phone = transformPhoneNumber(input)
        setMessage(true)
        await sendSms(phone)
      }
    },
    [
      input,
      sendEmail,
      sendSms,
      setValidInput,
      setMessage,
      validateEmail,
      validatePhoneNumber,
      transformPhoneNumber,
    ]
  )

  const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value.toLocaleLowerCase())
  }

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gradient-to-b from-case-grad-from to-case-grad-to">
      <div className="p-6 m-auto lg:max-w-xl ">
        <div className="mb-8">
          <img src={logo} alt="logo" className="w-25" />
        </div>
        {appLink ? (
          <Stores />
        ) : message ? (
          <div>
            <h2 className="text-xl text-center mb-8" style={{ color: "white" }}>
              We sent you a link to your{" "}
              {validateEmail(input) ? "email" : "phone"}
            </h2>
            <h2 className="text-xl text-center mb-8" style={{ color: "white" }}>
              Confirm your{" "}
              {validateEmail(input) ? "email address" : "phone number"} by
              clicking the link in your{" "}
              {validateEmail(input) ? "email" : "phone"}
            </h2>
          </div>
        ) : noPermissionsMessage ? (
          <h2 className="text-xl text-center mb-8" style={{ color: "white" }}>
            You do not have permissions to enter the Portal or the token has
            Expired.
          </h2>
        ) : loggingIn ? (
          <h2 className="text-xl text-center mb-8" style={{ color: "white" }}>
            Validating
          </h2>
        ) : (
          <div>
            <h1
              className="text-7xl text-center mb-8"
              style={{ color: "white" }}
            >
              Welcome
            </h1>
            <h2 className="text-xl text-center mb-8" style={{ color: "white" }}>
              Sign in with your phone number or email address
            </h2>
            <div className="mb-2 text-center">
              <TextField
                id={"outlined-helperText"}
                variant={"outlined"}
                placeholder="Phone number or email"
                size="small"
                onChange={inputChanged}
                value={input}
                type="text"
                helperText={!validInput ? "" : ""}
                style={{
                  backgroundColor: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                }}
                className="w-80"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin(e)
                  }
                }}
              />
              {!validInput && (
                <p className="text-red" style={{ color: "red" }}>
                  Please enter a valid email or phone number.
                </p>
              )}
            </div>
            <div className="mt-6 text-center">
              <Button
                disabled={!input.trim()}
                onClick={handleLogin}
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#0F2150",
                  color: "#fff",
                }}
                className="w-80"
              >
                Sign-in
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login
